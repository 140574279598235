<template>
	<div>
		<CustomTable
			ref="monte_resa"
			id_table="monte_resa_commande"
			primaryKey="id"
			:items="items"
			:busy="table_busy"
			:hrefsRoutes="config_table_hrefs"
			:base-filters="tableFilters"
		/>	
	</div>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: "Order",
		props: {
			items: { type: Array, default: () => [] },
			table_busy: { type: Boolean, default: false }
		},
		mixins: [Navigation],
		data () {
			return {
				events_tab: {
					"MonteReservation::RefreshOrder": () => { this.$refs.monte_resa.refreshTable() },
				},
				config_table_hrefs: {
					'expediteur.tiers_rs': {
						routeUniqueName: 'tierExpeFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'expediteur.tiers_id'
						}
					},
					'destinataire.tiers_rs': {
						routeUniqueName: 'tierDestFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'destinataire.tiers_id'
						}
					},
					'stallion.horse_nom': {
						routeUniqueName: 'stallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'mare.horse_nom': {
						routeUniqueName: 'mareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'stallion.horse_id'
						}
					},
					'contract.contract_num': {
						routeUniqueName: 'contractFiche',
						routeName: 'ContractFormv2',
						params: {
							contract_id: 'contract.contract_id'
						}
					},
				}
			}
        },
        methods: {
        	unselectAll() {
        		this.$refs.monte_resa.unselectAll()
        	}
        },
        computed: {
			tableFilters() {
				return {
					order_status: {
						column: 'order',
						operator: 'isEmpty'
					}
				}
			}
		},
		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
        }
	}
</script>
